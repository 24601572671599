
.chart-bar {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    &__graph {
        position: relative;
        margin: 0 0 0 -8px;
        overflow: hidden;
        box-sizing: border-box;
        flex-grow: 1;
        color: #313131;
    }
}
