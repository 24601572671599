
.search {
    width: 100%;
    position: relative;
    &__input {
        font-size: 1em;
        display: block;
        width: 100%;
        border: none;
        color: var(--body-text-color);
        box-sizing: border-box;
        position: relative;
        z-index: 200;
        transition: .06s border-color ease;
        &--expanded {
            background: var(--indexpage-search-background-color) !important;
            border: 2px solid var(--card-border-color) !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        &:focus {
            border-color: #2575ed !important;
        }
        &::placeholder {
            color: #777;
        }
        &::-webkit-input-placeholder {
            color: #777;
        }
    }
    &__right-ear {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 1000;
        box-sizing: border-box;
        padding: 6px;
    }
    &__go {
        padding: 0 8px;
        border-radius: 8px;
        background: #2575ed;
        color: #FFF;
        height: 100%;
        min-width: 64px;
        font-size: 16px;
        cursor: pointer;
    }
    &__loader {
        width: 18px;
        height: 18px;
        z-index: 900;
        margin-right: 8px;
    }
    &__loopa {
        left: 10px;
        width: 20px;
        height: 20px;
        top: 9px;
        opacity: .35;
        z-index: 100;
    }
    &__close {
        width: 1.625em;
        height: 1.625em;
        fill: currentColor;
        opacity: .7;
        padding: 8px;
        margin: -8px;
        margin-right: -4px;
        cursor: pointer;
        transition: .1s opacity ease;
        &:hover {
            opacity: 1;
        }
    }
}

.search-results {
    z-index: 100;
    width: 100%;
    position: absolute;
    top: 100%;
    border: 2px solid var(--card-border-color);
    border-top: none;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    color: var(--body-text-color);
    box-sizing: border-box;
    font-size: 16px;
    background: var(--indexpage-search-background-color);
    border-radius: 0 0 12px 12px;
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &__link {
        display: flex;
        flex-direction: column;
        padding: 12px 16px;
        line-height: 1.4;
        color: inherit;
        background: transparent;
        &:hover {
            background: rgba(142, 142, 142, 0.16);
            text-decoration: none;
        }
    }
    &__domain {
        font-size: 15px;
        font-weight: 500;
        &::after {
            content: '.ton';
            font-weight: normal;
            opacity: .6;
        }
    }
    &__name {
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        &__title {
            flex-shrink: 1;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &__category {
            display: inline-flex;
            align-items: center;
            font-weight: normal;
            opacity: .45;
            font-size: 12px;
            &::before {
                opacity: .65;
                content: '·';
                padding: 0 6px;
            }
        }
    }
    &__address {
        font-size: 13px;
        opacity: .7;
    }
}

.search-result {
    display: flex;
    align-items: center;
    &--compact {
        padding-left: 2px;
    }
    &__left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        position: relative;
        .search-result--compact & {
            display: none;
        }
    }
    &__address-icon {
        width: 42px;
        height: 42px;
        border-radius: 8px;
        background: var(--card-border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &__svg {
            width: 24px;
            height: 24px;
            fill: currentColor;
            margin-right: -1px;
        }
        .search-result--offsetted & {
            margin: 0 9px;
        }
        .search-result--compact & {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            box-shadow: 0 0 0 4px #1d1d1d;
            &__svg {
                width: 60%;
                height: 60%;
                margin-bottom: -1px;
            }
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &__right {
        overflow: hidden;
        .search-result--compact & {
            display: flex;
            align-items: center;
        }
    }
}
