
.ui-copy-button {
    position: relative;
    cursor: text;
    &__icon {
        fill: currentColor;
        user-select: none;
        opacity: .65;
        cursor: pointer;
        padding: 6px;
        margin: -6px -6px -8px -4px;
        width: 14px;
        height: 14px;
    }
    &:hover .ui-copy-button__icon {
        opacity: 1;
    }
    .clickable + & {
        margin-left: 0.5ex;
    }
}
