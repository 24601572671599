
.indexpage-search {
    max-width: 600px;
    font-size: 16px;
    .search__input {
        padding: 12px 96px 12px 24px;
        border-radius: 12px;
        border: 2px solid var(--card-border-color);
        box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
        background: var(--indexpage-search-background-color);
    }
    .search-results {
        padding-bottom: 10px;
        &__link {
            margin: 4px 4px -4px 6px;
            border-radius: 8px;
        }
    }
    .search-result {
        &__left {
            margin-left: -4px;
        }
    }
}

@media screen and (max-width: 480px) {
    .indexpage-search {
        .search__input {
            padding-left: 16px
        }
        .search-results {
            &__link {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
