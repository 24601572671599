
.ui-inline-jetton {
    display: inline-flex;
    align-items: center;
    &__value {
        margin-right: 4px;
    }
    &__link {
        display: flex;
        align-items: center;
    }
    &__avatar {
        margin: -4px -1px -4px 6px;
        width: 20px;
        height: 20px;
    }
}
