
.nft-preview-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.nft-preview-error {
    border-radius: 0.52rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    box-shadow: 0 0 0 1px var(--card-border-color) inset;
    background: var(--nft-preview-error-card-background);
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding-top: 12px;
        height: 100%;
    }
    &__image {
        background-color: transparent !important;
    }
    &__icon {
        width: 32px;
        margin-bottom: 16px;
        fill: currentColor;
        opacity: .8;
    }
    &__text {
        font-size: 15px;
        margin: 0 8px;
        font-weight: 500;
    }
    &__button {
        padding: 10px 12px 10px 18px;
        border-radius: 24px;
        margin-top: -1px; // optically compensate top border
        cursor: pointer;
        display: inline-flex;
        transition: .1.8s all ease;
        &:hover {
            background: rgba(0, 0, 0, 0.4);
        }
        svg {
            margin-left: -6px;
            margin-right: 6px;
            fill: currentColor;
        }
    }
}
