

.overscroll-disabled {
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.settings-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 12px;
  border: 1px solid rgba(0,0,0, 0.1);
  border-radius: 50%
}
