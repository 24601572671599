
.verifier-sources {
    display: flex;
    flex-direction: row;
    &__files {
        margin: 0 0 12px 12px;
        width: 240px;
        flex-shrink: 0;
        &__elevator {
            position: sticky;
            top: 12px;
        }
    }
    &__code {
        flex-grow: 1;
        overflow: auto;
        margin: 0 12px 12px 12px;
    }
}

.source-viewer-code__pre {
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.hljs-hack-lines {
    padding: 0 10px 0 0;
    text-align: right;
    color: var(--body-muted-text-color);
}

.hljs-hack-code {
    padding: 0 10px 0 4px;
}

@media all and (max-width: 480px) {
    .verifier-sources {
        flex-direction: column;
        &__files {
            width: unset;
            margin-right: 12px;
        }
        &__code {
            margin-top: 0;
        }
    }
}
