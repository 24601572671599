
.touch-panel {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &--active {
    z-index: 9999;
    pointer-events: auto;
  }
}

.touch-panel__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(219, 210, 255, 0.2);
}

.touch-panel__main-area {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 100%;
  z-index: 2;
  background: #d1b9ff;
  &--with-transition {
    transition: transform .15s ease;
  }
}

.touch-panel__header {
  height: 50px;
  width: 100%;
  background: rgba(0,0,0, .5);
}

.fade-in-enter-active {
  animation: fade .25s;
}

.fade-in-leave-active {
  animation: fade .3s reverse;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
